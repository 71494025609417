export { ReactComponent as AboutIcon } from './about.svg'
export { ReactComponent as AngularJSIcon } from './angularJS.svg'
export { ReactComponent as ArduinoIcon } from './arduino.svg'
export { ReactComponent as ArrowRightIcon } from './arrowRight.svg'
export { ReactComponent as Auth0Icon } from './auth0.svg'
export { ReactComponent as BackendIcon } from './backEnd.svg'
export { ReactComponent as BF2042Icon } from './bf2042.svg'
export { ReactComponent as CactusIcon } from './cactus.svg'
export { ReactComponent as CameraIcon } from './camera.svg'
export { ReactComponent as CatIcon } from './cat.svg'
export { ReactComponent as ChromeIcon } from './chrome.svg'
export { ReactComponent as CircleButtonIcon } from './circleButton.svg'
export { ReactComponent as CloseIcon } from './close.svg'
export { ReactComponent as CSS3Icon } from './css3.svg'
export { ReactComponent as CucumberIcon } from './cucumber.svg'
export { ReactComponent as CypressIcon } from './cypress.svg'
export { ReactComponent as DatabaseIcon } from './database.svg'
export { ReactComponent as DockerIcon } from './docker.svg'
export { ReactComponent as EmailIcon } from './email.svg'
export { ReactComponent as EmotionJSIcon } from './emotion.svg'
export { ReactComponent as EslintIcon } from './eslint.svg'
export { ReactComponent as ExpoIcon } from './expo.svg'
export { ReactComponent as ExpressJSIcon } from './expressJs.svg'
export { ReactComponent as FigmaIcon } from './figma.svg'
export { ReactComponent as FileIcon } from './file.svg'
export { ReactComponent as FrontendIcon } from './frontEnd.svg'
export { ReactComponent as GameControllerIcon } from './game-controller.svg'
export { ReactComponent as GetTheIdeaIcon } from './getTheIdea.svg'
export { ReactComponent as GitIcon } from './git.svg'
export { ReactComponent as GitLabIcon } from './gitLab.svg'
export { ReactComponent as GithubIcon } from './github.svg'
export { ReactComponent as GwtIcon } from './gwt.svg'
export { ReactComponent as HalfLifeIcon } from './halflife.svg'
export { ReactComponent as HibernateIcon } from './hibernate.svg'
export { ReactComponent as HikeIcon } from './hike.svg'
export { ReactComponent as HTML5Icon } from './html5.svg'
export { ReactComponent as IonicIcon } from './ionic.svg'
export { ReactComponent as JavaIcon } from './java.svg'
export { ReactComponent as JavascriptIcon } from './javascript.svg'
export { ReactComponent as JestIcon } from './jest.svg'
export { ReactComponent as JsonIcon } from './json.svg'
export { ReactComponent as LanguageIcon } from './language.svg'
export { ReactComponent as LinkedInIcon } from './linkedIn.svg'
export { ReactComponent as LitElementIcon } from './litElement.svg'
export { ReactComponent as MaterialUiIcon } from './materialui.svg'
export { ReactComponent as MobileIcon } from './mobile.svg'
export { ReactComponent as MochaIcon } from './mocha.svg'
export { ReactComponent as MongoDBIcon } from './mongodb.svg'
export { ReactComponent as MoonIcon } from './moon.svg'
export { ReactComponent as NextJSIcon } from './nextjs.svg'
export { ReactComponent as NodeJSIcon } from './nodejs.svg'
export { ReactComponent as NpmIcon } from './npm.svg'
export { ReactComponent as NxIcon } from './nx.svg'
export { ReactComponent as OracleIcon } from './oracle.svg'
export { ReactComponent as PaintIcon } from './paint.svg'
export { ReactComponent as PerlIcon } from './perl.svg'
export { ReactComponent as PostgresSqlIcon } from './postgresSQL.svg'
export { ReactComponent as PrettierIcon } from './prettier.svg'
export { ReactComponent as PythonIcon } from './python.svg'
export { ReactComponent as ReactQueryIcon } from './reactQuery.svg'
export { ReactComponent as ReactJSIcon } from './reactjs.svg'
export { ReactComponent as ReduxIcon } from './redux.svg'
export { ReactComponent as ScottPilgrimIcon } from './scottPilgrim.svg'
export { ReactComponent as ScrollTop } from './scrollTop.svg'
export { ReactComponent as SenchaIcon } from './sencha.svg'
export { ReactComponent as SentryIcon } from './sentry.svg'
export { ReactComponent as ShareIcon } from './share.svg'
export { ReactComponent as SocketIOIcon } from './socketIO.svg'
export { ReactComponent as SpiderManIcon } from './spidermask.svg'
export { ReactComponent as SpringIcon } from './spring.svg'
export { ReactComponent as StorybookIcon } from './storybook.svg'
export { ReactComponent as StyledComponentsIcon } from './styledComponents.svg'
export { ReactComponent as TechIcon } from './tech.svg'
export { ReactComponent as TelegramIcon } from './telegram.svg'
export { ReactComponent as TypescriptIcon } from './typescript.svg'
export { ReactComponent as ValentinaIcon } from './valentina.svg'
export { ReactComponent as VercelIcon } from './vercel.svg'
export { ReactComponent as VSCodeIcon } from './vscode.svg'
export { ReactComponent as WebpackIcon } from './webpack.svg'
export { ReactComponent as XataIOIcon } from './xataIO.svg'
export { ReactComponent as YarnIcon } from './yarn.svg'
